// Generated by Framer (6b748fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["gAUm2du0G", "SETaWVdrM", "og35AVvnf", "rxPUFgJPj", "gbTxZU0Uq"];

const serializationHash = "framer-zUSPc"

const variantClassNames = {gAUm2du0G: "framer-v-4qq7gh", gbTxZU0Uq: "framer-v-1aywi89", og35AVvnf: "framer-v-zgri1d", rxPUFgJPj: "framer-v-17fuogi", SETaWVdrM: "framer-v-cmhfgq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0.1, duration: 0.4, ease: [0, 0, 1, 1], type: "tween"}

const transition2 = {delay: 0.2, duration: 0.4, ease: [0, 0, 1, 1], type: "tween"}

const transition3 = {delay: 0.3, duration: 0.4, ease: [0, 0, 1, 1], type: "tween"}

const transition4 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const transition5 = {delay: 0, duration: 0.4, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"0.1 delay": "gAUm2du0G", "0.2 delay": "SETaWVdrM", "0.3 delay": "og35AVvnf", "No delay": "gbTxZU0Uq", "Opacity 0": "rxPUFgJPj"}

const getProps = ({height, id, opacity, title, width, ...props}) => { return {...props, aB__86o1F: opacity ?? props.aB__86o1F ?? 0.5, GezboX1Te: title ?? props.GezboX1Te ?? "Nav Link", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "gAUm2du0G"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;opacity?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GezboX1Te, aB__86o1F, JbFeMtJzuuukaY4tCS, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "gAUm2du0G", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({gbTxZU0Uq: {value: transition5}, og35AVvnf: {value: transition3}, rxPUFgJPj: {value: transition4}, SETaWVdrM: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-4qq7gh", className, classNames)} data-framer-name={"0.1 delay"} layoutDependency={layoutDependency} layoutId={"gAUm2du0G"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{rxPUFgJPj: {opacity: 0}}} {...addPropertyOverrides({gbTxZU0Uq: {"data-framer-name": "No delay"}, og35AVvnf: {"data-framer-name": "0.3 delay"}, rxPUFgJPj: {"data-framer-name": "Opacity 0"}, SETaWVdrM: {"data-framer-name": "0.2 delay"}}, baseVariant, gestureVariant)}><motion.div className={"framer-zvf3sf"} data-framer-name={"Nav Link"} layoutDependency={layoutDependency} layoutId={"RiNS5xIWT"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS01MDA=", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-line-height": "2em", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-JbFeMtJzu-uukaY4tCS))", "--framer-text-transform": "uppercase"}}>Nav Link</motion.p></React.Fragment>} className={"framer-sbc8wd"} data-framer-name={"Label"} fonts={["GF;Manrope-500"]} layoutDependency={layoutDependency} layoutId={"au2ZijweG"} style={{"--extracted-r6o4lv": "var(--variable-reference-JbFeMtJzu-uukaY4tCS)", "--framer-paragraph-spacing": "0px", "--variable-reference-JbFeMtJzu-uukaY4tCS": JbFeMtJzuuukaY4tCS, opacity: aB__86o1F}} text={GezboX1Te} verticalAlignment={"center"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zUSPc.framer-yo53pq, .framer-zUSPc .framer-yo53pq { display: block; }", ".framer-zUSPc.framer-4qq7gh { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-zUSPc .framer-zvf3sf { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-zUSPc .framer-sbc8wd { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zUSPc.framer-4qq7gh, .framer-zUSPc .framer-zvf3sf { gap: 0px; } .framer-zUSPc.framer-4qq7gh > *, .framer-zUSPc .framer-zvf3sf > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-zUSPc.framer-4qq7gh > :first-child, .framer-zUSPc .framer-zvf3sf > :first-child { margin-top: 0px; } .framer-zUSPc.framer-4qq7gh > :last-child, .framer-zUSPc .framer-zvf3sf > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 62.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"SETaWVdrM":{"layout":["auto","fixed"]},"og35AVvnf":{"layout":["auto","fixed"]},"rxPUFgJPj":{"layout":["auto","fixed"]},"gbTxZU0Uq":{"layout":["auto","fixed"]}}}
 * @framerVariables {"GezboX1Te":"title","aB__86o1F":"opacity"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerP3PxYaZNX: React.ComponentType<Props> = withCSS(Component, css, "framer-zUSPc") as typeof Component;
export default FramerP3PxYaZNX;

FramerP3PxYaZNX.displayName = "Nav Link delay";

FramerP3PxYaZNX.defaultProps = {height: 36, width: 62.5};

addPropertyControls(FramerP3PxYaZNX, {variant: {options: ["gAUm2du0G", "SETaWVdrM", "og35AVvnf", "rxPUFgJPj", "gbTxZU0Uq"], optionTitles: ["0.1 delay", "0.2 delay", "0.3 delay", "Opacity 0", "No delay"], title: "Variant", type: ControlType.Enum}, GezboX1Te: {defaultValue: "Nav Link", displayTextArea: false, title: "Title", type: ControlType.String}, aB__86o1F: {defaultValue: 0.5, max: 1, min: 0, step: 0.01, title: "Opacity", type: ControlType.Number}} as any)

addFonts(FramerP3PxYaZNX, [{explicitInter: true, fonts: [{family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_A87jxeN7B.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})